(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/analytics-promotion/assets/javascripts/promoclick.js') >= 0) return;  svs.modules.push('/components/components/analytics-promotion/assets/javascripts/promoclick.js');

var svs = svs || {};

(function(svs) {
  'use strict';
  svs.components = svs.components || {};
  svs.components.analytics = svs.components.analytics || {};
  svs.core = svs.core || {};
  svs.core.analytics = svs.core.analytics || {};
  svs.core.analytics.adl = svs.core.analytics.adl || {};
  svs.components.analytics.trackPromoClick = function(evt, cb) {
    var msg = new svs.core.log.Message(svs.core.log.MessageType.event);
    var { destPage } = msg;

    var data = {
      event: 'promoClick',
      eventInfo: {
        type: 'Promotion',
        eventAction: 'Click'
      },
      component: {
        componentID: evt.id || destPage, 
        componentName: evt.name,
        componentPosition: evt.position
      }
    };

    if (evt && evt.opt_label) {
      data.eventInfo.eventName = evt.opt_label;
    }
    dataLayerPush(data);
    window.adobeDataLayer.adlPush('promoClick', {
      site: svs.core.analytics.adl.site,
      consent: svs.core.analytics.adl.consent,
      user: svs.core.analytics.adl.user,
      page: svs.core.analytics.adl.page,
      category: evt.id || destPage,
      action: evt.name,
      label: evt.position,
      value: ''
    });

    msg.eventCategory = 'Internal Campaign';
    msg.eventAction = '[' + evt.position + ']';
    msg.eventLabel = 'promotion interaction';
    msg.eventValue = 1;
    svs.core.log.track('svs.components.analytics', msg);

    if (typeof cb === 'function') {
      svs.core.log.flush();
      setTimeout(cb, 100); 
    }
  };
})(svs);


 })(window);