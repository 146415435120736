(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/analytics-promotion/assets/javascripts/observeimpression.js') >= 0) return;  svs.modules.push('/components/components/analytics-promotion/assets/javascripts/observeimpression.js');
"use strict";


svs.components = svs.components || {};
svs.components.analytics = svs.components.analytics || {};
svs.core = svs.core || {};
svs.core.analytics = svs.core.analytics || {};
svs.core.analytics.adl = svs.core.analytics.adl || {};
const msg = new svs.core.log.Message(svs.core.log.MessageType.event);
const {
  destPage
} = msg;

const getWidgetPosition = el => {
  const widgetStdClass = 'widget';
  const allWidgetsInDom = document.getElementsByClassName(widgetStdClass);
  const thisWidgetElement = el.closest('div.widget');
  return Array.prototype.indexOf.call(allWidgetsInDom, thisWidgetElement) + 1;
};
const sendImpression = element => {
  const comp = {
    componentType: 'promo',
    componentID: element.dataset.track || destPage,
    componentName: element.dataset.track || element.dataset.impressionTracking,
    componentPosition: getWidgetPosition(element)
  };
  const evt = {
    event: 'promoImpression',
    component: [comp]
  };
  if (element.dataset.label) {
    evt.eventInfo = {
      eventName: element.dataset.label
    };
  }
  window.dataLayerPush(evt);
  window.adobeDataLayer.adlPush('promoView', {
    site: svs.core.analytics.adl.site,
    consent: svs.core.analytics.adl.consent,
    user: svs.core.analytics.adl.user,
    page: svs.core.analytics.adl.page,
    category: comp.componentID,
    action: comp.componentName,
    label: element.dataset.areaName || '',
    value: ''
  });

  msg.eventCategory = 'Impression';
  msg.eventAction = "[".concat(comp.componentPosition, "]");
  msg.eventLabel = comp.componentName;
  msg.eventValue = 1;
  svs.core.log.track('svs.components.analytics', msg);
};

svs.components.analytics.testPromoImpression = element => {
  sendImpression(element);
};
svs.components.dataAttribute.track('data-impression', element => {
  sendImpression(element);
});

 })(window);